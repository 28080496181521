// src/i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'


i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    resources: {
        en: {
            translation: {
                About: "About",
                Heroes: "Heroes",
                Maps: "Maps",
                Community: "Community",
                News: "News",
                en: "EN",
                th: "TH",
                EN: "English",
                TH: "Thai",
                Playnow: "PLAY NOW",
                Seemore: "See More",
                Send: "SEND",
                Gamemodeoverview: "Game mode overview",
                BattleRoyal: "Battle Royal",
                DuelMode: "Duel Mode",
                Dominion: "Dominion",
                champion: "Champions",
                Fighter: "Fighter",
                Assassin: "Assassin",
                Tank: "Tank",
                HP: "HP",
                Speed: "Speed",
                Attack: "Attack",
                SpecialSkills: "Special Skill",
                Normal: "Normal",
                UltimateSkill: "Ultimate Skill",
                Latestnews: "Latest News",
                Stayup: "Join the Ultimate Battle!",
                Header1: "The next Evolution of Battles",
                SubHeader1: "Test your skills in Battle Royal mode or face other players in 2x2 or 3v3 mode!",
                TitleStory: "Long time ago...",
                Story1: "The Earth was full of many resources and filled with various species of creatures that have their own civilizations. Millions of years have passed, Earth's resources are keep decreasing... All creatures on the Earth waged war. Everything getting madness, everyone are fight for various resources.",
                Story2: "The war lasted for many decades. Everything is ruined...Everything was getting bad that it was out of control... And that day.. There's some living being was rise from the sky and claimed himself was 'A God'.",
                Story3: "A God has lost patience with the thirst for power and such a savagery of these creatures, that cause damage to the world for such a long time... and to be a decisive decision of God, then proposed a solution to this ever-ending of war. By building a sky-high arena, to fight with many challenger.",
                Story4: "Everyone have to fight to each other, no matter how they love each other in before... and the 'God' said... for who winner of this challenge of skyline platform. Can wishes for anything in the world their need. And this...is the beginning of the madness race of Overwar Legends!",
                Stayup1: "Registering for our newsletter, you will gain access to a world of exclusive News, updates and events of Overwar Legends!",
                Stayup2: "I have read and agree to abide by the",
                Terms: "Terms of Service",
                and: "and",
                Policy: "Privacy Policy",
                Thanks1: "Thanks for joining!",
                Thanks2: "You’ll be sent the next issue of our newsletter shortly."
            },
        },
        th: {
            translation: {
                About: "เกี่ยวกับ",
                Heroes: "ฮีโร่",
                Maps: "แผนที่",
                Community: "คอมมูนิตี้",
                News: "ข่าวสาร",
                en: "EN",
                th: "TH",
                EN: "อังกฤษ",
                TH: "ไทย",
                Playnow: "เริ่มเล่น",
                Seemore: "ดูเพิ่มเติม",
                Send: "ส่ง",
                Gamemodeoverview: "ภาพรวมโหมดเกมส์",
                BattleRoyal: "แบทเทิลรอยัล",
                DuelMode: "โหมดการต่อสู้",
                Dominion: "อาณาจักร",
                champion: "แชมเปี้ยน",
                Fighter: "นักรบ",
                Assassin: "นักฆ่า",
                Tank: "แท็งก์",
                HP: "เลือด",
                Speed: "ความเร็ว",
                Attack: "โจมตี",
                SpecialSkills: "สกิลพิเศษ",
                Normal: "โจมตีปกติ",
                UltimateSkill: "อัลติเมท",
                Latestnews: "ข่าวล่าสุด",
                Stayup: "เข้าร่วมการต่อสู้ขั้นสุดยอด!",
                Header1: "วิวัฒนาการการต่อสู้ครั้งต่อไป",
                SubHeader1: "ทดสอบทักษะของคุณในโหมด Battle Royal หรือเผชิญหน้ากับผู้เล่นคนอื่นในโหมด 2x2 หรือ 3v3!",
                TitleStory: "นานแสนนาน...",
                Story1: "โลกเต็มไปด้วยทรัพยากรหลายประการและเต็มไปด้วยสิ่งมีชีวิตชนิดต่าง ๆ ที่มีวัฒนธรรมของตนเอง นับล้านปีผ่านไป ทรัพยากรของโลกกำลังลดลง... สิ่งมีชีวิตทุกชนิดบนโลกได้ต่อสู้กัน ทุกอย่างกำลังหลงลืม, ทุกคนต่อสู้เพื่อทรัพยากรต่าง ๆ",
                Story2: "สงครามนี้ก็ดำเนินต่อมาเป็นทศวรรษหลายปี ทุกสิ่งสิ่งเปรียบเสีย... ทุกอย่างเลวร้ายจนไม่สามารถควบคุมได้... และในวันนั้น... มีสิ่งมีชีวิตบางอย่างปรากฏตัวมาจากท้องฟ้าและประกาศตนเองว่า 'เทพเจ้า",
                Story3: "เทพเจ้าได้สูญเสียความอดทนต่อความกระหายน้ำของอำนาจและความดุร้ายของสิ่งมีชีวิตเหล่านี้ ซึ่งทำให้โลกเสียหายมานานนาน... และเพื่อเป็นการตัดสินของเทพเจ้า เขาได้นำเสนอวิธีการแก้ไขสงครามที่ไม่สิ้นสุดนี้ โดยการสร้างสนามสูงในท้องฟ้า เพื่อต่อสู้กับผู้ท้าทุกคน",
                Story4: "ทุกคนต้องต่อสู้กัน ไม่ว่าพวกเขาจะรักกันมากน้อยแค่ไหนในอดีต... และ 'เทพเจ้า' กล่าว... สำหรับผู้ชนะในการท้าทายบนแพลตฟอร์มบนฟ้านี้ สามารถปรารถนาสิ่งใดก็ได้ในโลกตามที่พวกเขาต้องการ และนี้... เป็นเรื่องเริ่มต้นของการแข่งขันปลุกความบ้าคลั่งของตำนาน Overwar!",
                Stayup1: "การลงทะเบียนรับจดหมายข่าวของเรา คุณจะสามารถเข้าถึงโลกแห่งข่าวสาร การอัปเดต และกิจกรรมพิเศษของ Overwar Legends!",
                Stayup2: "ฉันได้อ่านและตกลงที่จะปฏิบัติตาม",
                Terms: "เงื่อนไขการให้บริการ",
                and: "และ",
                Policy: "นโยบายความเป็นส่วนตัว",
                Thanks1: "ขอบคุณที่ร่วมรับชม!",
                Thanks2: "คุณจะได้รับฉบับของจดหมายข่าวของเราเร็ว ๆ นี้"
            },
        },
    },
})

export default i18n
